:root {
  --animate-duration: 600ms;
}

.card {
  /* Sizing */
  height: 100%;
  width: 100%;

  transform-style: preserve-3d;

  /* Content Alignment */
  position: relative;

  /* Styling */
  border-radius: 5px;
  border: 5px solid white;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.8);

  user-select: none;
  cursor: pointer;
}

.card-front,
.card-back {
  /* Sizing */
  height: 100%;
  width: 100%;

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  /* Alignment */
  position: absolute;

  /* Content Alignment */
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-front {
}

.card-back {
  transform: rotateY(180deg);
}
.flip-exit {
  transform: rotateY(180deg);
}

.flip-exit-active {
  transform: rotateY(0deg);
  transition: transform 300ms;
  transition-timing-function: cubic-bezier(0.57, 0.21, 0.69, 1.25);
}

.flip-exit-done {
  transform: rotateY(0deg);
}

.flip-enter {
  transform: rotateY(0deg);
}

.flip-enter-active {
  transform: rotateY(180deg);
  transition: transform 300ms;
  transition-timing-function: cubic-bezier(0.57, 0.21, 0.69, 1.25);
}

.flip-enter-done {
  transform: rotateY(180deg);
}

.done-enter-done {
  animation-duration: calc(var(--animate-duration) * 0.75);
  animation-name: bounceIn;
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
  }

  from {
    transform: translate(0px, 0px) rotateY(180deg);
  }

  10% {
    transform: translate(0px, -5px) rotateY(180deg);
  }
  20% {
    transform: translate(0px, -10px) rotateY(180deg);
  }
  30% {
    transform: translate(0px, -15px) rotateY(180deg);
  }

  40% {
    transform: translate(0px, -20px) rotateY(180deg);
  }
  50% {
    transform: translate(0px, -25px) rotateY(180deg);
  }
  60% {
    transform: translate(0px, -20px) rotateY(180deg);
  }
  70% {
    transform: translate(0px, -15px) rotateY(180deg);
  }

  80% {
    transform: translate(0px, -10px) rotateY(180deg);
  }
  90% {
    transform: translate(0px, -5px) rotateY(180deg);
  }

  to {
    transform: translate(0px, 0px) rotateY(180deg);
  }
}

.bounceIn {
  animation-duration: calc(var(--animate-duration) * 0.75);
  animation-name: bounceIn;
}
